export default class StudentsPlanningIndex {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    const stickyExamHeight = $("#sticky-exam").height() || 0;
    const navbarHeight = $("#navbar").height() || 0;
    
    
    if ($('.event-entry[data-done="true"]').length > 0) {
      const firstDayElement = $("#first-day");
      const targetOffset = firstDayElement.length > 0 
        ? firstDayElement.offset().top - stickyExamHeight - navbarHeight - 50 
        : $('.event-entry').last().offset().top - stickyExamHeight - navbarHeight - 50;
    
      $('html, body').animate({
          scrollTop: targetOffset
      }, 500);
    }
  
    $('#load-more-btn').on('click', this.loadMoreEntries);
  }

  loadMoreEntries() {
    let lastDate = $('.day-container').last().data('date');
    let url = $("#entries-container").data('url'); 

    $.ajax({
      url: url,
      type: 'GET',
      data: { last_date: lastDate },
      dataType: 'html',
      success: function (response) {
        $('#entries-container').append(response); 

        if ($('.day-container[data-last-entry="true"]').length) {
          $('#load-more-btn').hide();
        }
      },
    });
  }

  onDestroy() {
  }
}
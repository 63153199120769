export default class StudentsTutosIndex {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $('[id^="video-wrapper-"]').each(function() {
      const index = $(this).data('index');
      const iframe = $(`#video-iframe-${index}`);
      const loadingIndicator = $(`#loading-indicator-${index}`);

      iframe.on('load', function() {
        loadingIndicator.hide();
        iframe.show();
      });
    });

    $('#toggle-view').on('click', this.toggleView.bind(this));
    $('#reset-view').on('click', this.resetView.bind(this));
  }

  toggleView() {
    $('.col-md-8').each(function() {
      $(this).toggleClass('col-md-6 col-md-8');
    });
    $('#toggle-view i').toggleClass('window-icon window-icon-primary');
    $('#reset-view i').removeClass('desktop-icon-primary').addClass('desktop-icon');
  }

  resetView() {
    $('.col-md-6').each(function() {
      $(this).removeClass('col-md-6').addClass('col-md-8');
    });
    $('#reset-view i').toggleClass('desktop-icon desktop-icon-primary');
    $('#toggle-view i').removeClass('window-icon-primary').addClass('window-icon');
  }
}
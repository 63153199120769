export default class StudentsDashboardIndex {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $('.feedback-close-btn').on('click', function() {
      $(this).closest('.feedback-wrapper').hide();
    });
  }
}
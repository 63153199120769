export default class ContractSignature {

    constructor() {
        this.has_signed = false;
        if($("#yousign-iframe").length>0) {
            this.check_status_link = $("#yousign-iframe").data("statusLink");
            this.yousign = new Yousign({
                signatureLink: $("#yousign-iframe").data("externalLink"),
                iframeContainerId: 'yousign-iframe',
                isSandbox: true,
            });
            this.bindEvents();
        }
    }

    bindEvents() {
        var self = this;
        
        window.addEventListener('resize', () => {
            self.resizeIframe();
        });
        this.yousign.onStarted((data) => {
            console.log("Signer has opened the signature");
            console.log(data);
        });
          
        this.yousign.onSuccess((data) => {
            console.log("Signer has successfully signed");
            self.has_signed = true;
            self.checkStatus();
            console.log(data);
        });
          
        this.yousign.onError((data) => {
            console.log("Signer encountered an error when signing");
            console.log(data);
        });
          
        this.yousign.onPing((data) => {
            console.log("Ping - The signature request is loaded");
            console.log(data);
        });
          
        this.yousign.onDeclined((data) => {
            console.log("Declined - The signer declined the signature");
            console.log(data);
        });

        this.resizeIframe();
    }

    checkStatus() {
        console.log("checkStatus called -> "+this.has_signed);
        console.log(this.check_status_link);
        //Ajax request to this.check_status_link
        if(this.has_signed) {
        
            $.ajax({
                url: this.check_status_link,
                method: "GET"
            })
        }
    }

    resizeIframe() {
        const iframe = document.getElementById('yousign-iframe');
        const viewportHeight = window.innerHeight;
        iframe.style.height = (viewportHeight - 72) + 'px';
    }
}
import Routes from "./config/Routes";
import ConfigApplication from "../config/Application";
import ConfirmationModal from "./utils/ConfirmationModal";
import TemplateValidation from "./utils/TemplateValidation";
import CustomSelect from "./utils/CustomSelect";
import Toast from "./utils/Toast";
import Cookie from "../utils/Cookie";

export default class Application extends ConfigApplication{
    constructor() {
        super();
        this.routes = new Routes();
        this.confirmationModal = new ConfirmationModal();
        this.templateValidation = null;

        this.cookie = Cookie;

        this.tooltips = [];
        this.customSelects = [];
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
    }
    
    pageChanged() {
        super.pageChanged();
        this.dismissible();

        if(window.validationData !== undefined){
            this.setTemplateValidation(window.validationData);
        }
        this.setTooltip();
        this.setCustomSelect();
        this.setMarquee();

        this.onAlertChange();
        this.onNoticeChange();
        this.onErrorChange();
        this.onSuccessChange();
    }

    bindEventsAfterPageChanged() {
        super.bindEventsAfterPageChanged();

        $('.js-delete-confirm').unbind('click').click(this.delete_confirm)
    }

    onAlertChange(){
        if(window.warning !== null && window.warning !== undefined && window.warning !== '') {
            this.alertNotification(window.warning);
        }
    }

    onNoticeChange(){
        if(window.notice !== null && window.notice !== undefined && window.notice !== '') {
            this.noticeNotification(window.notice);
        }
    }

    onErrorChange(){
        if(window.error !== null && window.error !== undefined && window.error !== '') {
            this.errorNotification(window.error);
        }
    }

    onSuccessChange(){
        if(window.success !== null && window.success !== undefined && window.success !== '') {
            this.successNotification(window.success);
        }
    }

    successNotification(msg){
        Toast.success(msg)
    }

    errorNotification(msg){
        Toast.error(msg)
    }

    noticeNotification(msg){
        Toast.info(msg)
    }

    alertNotification(msg){
        Toast.warning(msg)
    }

    delete_confirm() {
        let self = application
        let title = $(this).data("title");
        let message = $(this).data("message");
        let btnPositive = $(this).data("btn-confirm");
        let btnCancel = $(this).data("btn-cancel");
        let url = $(this).data("url");
        let id = $(this).data("id");
        self.confirmationModal.displayConfirm(
            title,
            message,
            {
                positive_btn: {
                    type: "danger",
                    label: btnPositive,
                    callback: function() {
                        let form = document.createElement('form');
                        form.style.visibility = 'hidden';
                        form.action = url;
                        form.method = 'post'

                        let input = document.createElement('input');
                        input.name = '_method';
                        input.value = 'delete';
                        input.type = 'hidden'
                        form.appendChild(input);

                        let inputToken = document.createElement('input');
                        inputToken.name = 'authenticity_token';
                        inputToken.value = $('meta[name="csrf-token"]').attr('content');
                        inputToken.type = 'hidden'
                        form.appendChild(inputToken);

                        document.body.appendChild(form);
                        form.submit();
                    }
                },
                negative_btn: {
                    label: btnCancel
                }
            });
    }

    dismissible(){
        $("div[data-dismiss]").each(function (){
            //$(this).delay(parseInt($(this).data("dismiss"))).fadeOut();
        })
    }

    setTemplateValidation(validationData, form_element = '#crud_form'){
        if(this.templateValidation !== null){
            this.templateValidation.onDestroy();
            this.templateValidation = null;
        }

        if(validationData !== undefined){
            this.templateValidation = new TemplateValidation(validationData, form_element);
        }
    }

    setTooltip(){
        if(this.tooltips !== null){
            this.tooltips.forEach(tooltip => {
                tooltip.dispose();
            })
        }
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        this.tooltips = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    }

    setCustomSelect(){
        if(this.customSelects !== null){
            this.customSelects.forEach(customSelect => {
                customSelect.onDestroy();
            })
        }
        const customSelectList = document.querySelectorAll('.form-custom-select')
        this.customSelects = [...customSelectList].map(customSelectEl => new CustomSelect(customSelectEl))
    }

    setMarquee() {
        const marquee = document.querySelector( '.lna-animate' );
        if ( null !== marquee ) {
            this.toggleMarquee();
            window.addEventListener( 'resize', this.toggleMarquee );
        }
    }

    toggleMarquee() {
        const marquee = document.querySelector( '.lna-animate' );
        if ( marquee.offsetWidth >= window.innerWidth - 30 ) {
            marquee.classList.add( 'lna-scroll-left-animate' );
        } else {
            marquee.classList.remove( 'lna-scroll-left-animate' );
        }
    }
}
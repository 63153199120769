import CartRecap from "../../utils/checkout/CartRecap";
import PayPlugIntegrated from "../../utils/checkout/PayPlugIntegrated";
import Oney from "../../utils/checkout/Oney";
import Cpf from "../../utils/checkout/Cpf";
import Toast from "../../utils/Toast";

export default class Payment {

    constructor() {
        this.cart_recap = new CartRecap();
        try {
            this.pay_plug_integrated = new PayPlugIntegrated();
        } catch (e) {
            console.log("Can't initiate Payplug");
        }
        this.oney = new Oney();
        this.cpf = new Cpf();
        this.minPriceToInstallment = 0;
        this.currentPrice = 0;
        this.gtmDataLayer = {};
        this.initMethods();
        this.bindEvents();
        this.setGtmDataLayer('begin_checkout');

        window.application.setOnDataChangeListener(this);
    }

    initMethods() {
        let self = this;
        this.onInstallmentChange = () => self.updateCart();
        this.onSubmitForm = (e) => self.formSubmitted(e);
        this.onCartUpdated = () => self.paymentMethodsUpdate();
        this.onCguChanged = (e) => self.cguChanged(e);
    }


    bindEvents() {
        $(`input[name="record_payment[installment_count]"]`).change(this.onInstallmentChange);
        $('.submit-cart').click(this.onSubmitForm);
        $('.cart-recap').on('cart:updated', this.onCartUpdated);
        $('.check-cgu').change(this.onCguChanged)

        // if .card-cpf has class cpf-not-allowed
        if ($('.card-cpf').hasClass('cpf-not-allowed')) {
            $('input[name="record_payment[installment_count]"][value="installment_1x"]').prop('checked', true).trigger('change');
        }

        $(".card-cpf").off('click').on('click', function() {
            if ($(this).hasClass('cpf-not-allowed')) {
                $('#modal_not_allowed_cpf').modal('show');
            }
        });

        $("#unselect-cpf").off('click').on('click', function() {
            $('input[name="record_payment[installment_count]"][value="installment_1x"]').prop('checked', true);
            $('#modal_not_allowed_cpf').modal('hide');
        });
    }

    onDataChanged(data) {
        if( data.minPriceToInstallment !== undefined ) {
            this.minPriceToInstallment = data.minPriceToInstallment;
            this.currentPrice = data.currentPrice;
            this.paymentMethodsUpdate();
        }
    }

    onDestroy() {
        this.cart_recap.onDestroy();
        this.pay_plug_integrated.onDestroy();
        this.oney.onDestroy();
        this.cpf.onDestroy();
    }

    updateCart() {
        let paymentMethod = $(`input[name="record_payment[installment_count]"]:checked`).val();
        this.cart_recap.update({
            record_payment: {
                installment_count: paymentMethod
            }
        }, paymentMethod !== 'installment_cpf');
    }

    formSubmitted(e) {
        e.preventDefault();
        let paymentMethod = $(`input[name="record_payment[installment_count]"]:checked`).val();
        switch (paymentMethod) {
            case 'installment_1x':
                this.pay_plug_integrated.submitCardFrom();
                break;
            case 'installment_3x':
                this.oney.submitPayment();
                break;
            case 'installment_4x':
                this.oney.submitPayment();
                break;
            case 'installment_cpf':
                this.cpf.submitPayment();
                break;
            default:
                break;
        }
    }

    paymentMethodsUpdate() {
        this.currentPrice = parseFloat($('p[data-price]').data('price'))
        if (this.currentPrice >= this.minPriceToInstallment) {
            $('.payment-oney').removeClass('d-none');
        } else {
            $('.payment-oney').addClass('d-none');
            let val = $('input[name="record_payment[installment_count]"]:checked').val();
            if(val === 'installment_3x' || val === 'installment_4x') {
                $('input[name="record_payment[installment_count]"][value="installment_1x"]')
                    .prop('checked', true)
                    .trigger('change');
            }
        }
    }

    cguChanged(e) {
        let el = $(e.currentTarget);
        $('.check-cgu').prop('checked', el.is(':checked'));
        if(el.is(':checked')) {
            $('.submit-cart').removeClass('disabled');
        } else {
            $('.submit-cart').addClass('disabled');
        }
    }

    setGtmDataLayer(gtmEvent) {
        this.gtmDataLayer.event = gtmEvent;
        window.dataLayer.push(this.gtmDataLayer);
    }

    invalidPromoCode() {
        Toast.error(I18n.t('common.js_validation.promo_code.error'));
    }
}